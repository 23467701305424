<template>
    <div class="imprint text-view">
        <v-container fluid>
            <v-row align="start" justify="start" class="mt-0">
                <v-col cols="12">
                    <p class="text-h4">
                        Impressum
                    </p>
                    <p>Angaben gemäß § 5 TMG:</p>
                    <p>Herausgeber:<br />Ben R. Hansen</p>
                    <p>
                        BEN digital services GmbH<br />
                        Schloßstr. 19<br />
                        82031 Grünwald
                    </p>
                    <p>
                        Telefon<br />
                        +49 721 754 052 70<br />
                    </p>
                    <p>
                        E-Mail: info@bendigital.de<br />
                        Internet https://bendigital.de
                    </p>
                    <p>
                        Vertretungsberechtigter Geschäftsführer: Ben R. Hansen
                    </p>
                    <p>
                        Eingetragen im Handelsregister<br />
                        Registergericht: Jena<br />
                        Registernummer: HRB 518574<br />
                        USt-IdNr: DE339926352
                    </p>
                    <p>
                        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                        Benjamin Robert Hansen
                    </p>
                    <p>
                        Inhalte:<br />
                        Wir haben die Inhalte unserer Seiten mit größtmöglicher
                        Sorgfalt erstellt, können aber für die ständige
                        Vollständigkeit, Aktualität und Richtigkeit der von uns
                        erstellten Inhalte keine Gewähr übernehmen. Wir sind
                        nicht dazu verpflichtet fremde Informationen auf unseren
                        Seiten zu überwachen oder Sachverhalte zu ermitteln, die
                        auf Rechtsverletzungen durch Dritte auf unseren Seiten
                        begangen werden. Zudem übernehmen wir keine Haftung für
                        die Inhalte externer Links, da für den Inhalt verlinkter
                        Seiten ausschließlich deren Betreiber verantwortlich
                        sind. Wir sind aber natürlich jederzeit gerne bereit,
                        Hinweisen auf etwaige Rechtsverletzungen durch Dritte
                        auf unseren Websites nachzugehen und gegebenenfalls
                        natürlich bereit derartige Rechtsverletzungen zu
                        unterbinden bzw. rechts verletzende Inhalte zu
                        entfernen. Auch Hinweisen auf rechtswidrige Inhalte auf
                        verlinkten Websites gehen wir nach und entfernen die
                        entsprechende Verlinkung gegebenenfalls umgehend.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Imprint',
};
</script>
